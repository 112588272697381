import { Box } from '@mui/material';
import { Outlet } from "react-router-dom";
import NavBar from './NavBar';

const Layout = () => {
    return (
        <>
          <Box sx={{ display: "flex",flexDirection:"column",overflow:"auto" }}>
            <NavBar />
            <Box sx={{paddingLeft:"6%",marginTop:"10vh"}}>
              <Outlet />
            </Box>
          </Box>
        </>
      );
}

export default Layout
