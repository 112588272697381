import { Box, Typography } from '@mui/material'

const PreviewComponent = ({label,value}:any) => {
  return (
    <Box sx={{ gap:"0px", width: { xs: '90%', sm: '60%', md: '40%' } }}>
      <Typography sx={{ my: '5px',fontSize:"16px",marginTop: '15px',fontWeight:"500",opacity:"60%" }}>{label}</Typography>
      <Typography variant='h6' sx={{color:"#020202",opacity:"100%",fontWeight:"600"}}>{value}</Typography>
    </Box>
  )
}

export default PreviewComponent
