//Images
import banner from "./images/loginBanner.png";
import telanganaLogo from "./images/telanganaLogo.png";
import setwinLogo from "./images/setwinLogo.png";
import successLogo from "./images/successLogo.json"

//Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export {
    banner,
    telanganaLogo,
    setwinLogo,
    successLogo
};

export const Icons={
    ArrowBackIcon,
}

