import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getByMobileNumber } from '../api/service/index';
import { banner } from '../assets';
import FormInput from '../components/FormFields/FormInput';
import Loader from '../components/Loader';
import Toast from '../components/Toast/Toast';
import { StyledFormComponent, StyledImageBackground, StyledLoginBackground } from '../styles/styledComponent';
import { loginValidationSchema } from '../validations/LoginValidation';


const Login = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [mobile, setMobile] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>(""); 
  
  const defaultValues = {
    mobileNo: ""
  };
  const isMobile = useMediaQuery('(max-width:960px)');

  
  const { handleSubmit, control,reset, formState: { errors } } = useForm({
    defaultValues,
    resolver: yupResolver(loginValidationSchema)
  });

  const { data: studentData, isLoading } = useQuery(
    ["studentMobileData", mobile],
    getByMobileNumber,
    {
      enabled: !!mobile,
      onSuccess: (data: any) => {
        navigate("/form", { state: { studentData: data?.data } });
      },
      onError: (error: any) => {
        setTimeout(() => {
          setErrorMessage(error?.response?.data?.message || "An error occurred");
          setTimeout(() => {
            setErrorMessage(""); 
          }, 3000);
        }, 100)  
      },
    }
  );

  const handleLogin=()=>{
    queryClient.invalidateQueries('studentMobileData')
  }

  const onSubmit = (data: any) => {
    setMobile(data.mobileNo);
    handleLogin();
  };
  

  return (
    <StyledLoginBackground>      
      <StyledImageBackground>
        <img src={banner} alt='banner' width={"100%"} style={{ width: "100%", height: "100%", objectFit: isMobile ? "cover" : "contain" }} />
      </StyledImageBackground>
      <StyledFormComponent>
        <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'flex', width: "100%", flexDirection: 'column', gap: '10px' }}>
            <Stack direction={'row'} gap={0.5}>
              <Typography variant='h6' sx={{ fontWeight: "500" }}>Enter Mobile Number</Typography>
              <Typography color="red">*</Typography>  
            </Stack>
            <FormInput control={control} name={'mobileNo'} label={'Enter the 10 digit mobile number'} />
          </Box>
          <Box sx={{ mt: "23px" }}>
            <Button
              type='submit'
              variant='contained'
              sx={{ width: { xs: "30%", sm: "25%", md: "20%" },height:"50px", borderRadius: "8px" }}
            >
              Submit {isLoading && <Loader/>}
            </Button>
          </Box>
          {errorMessage && (
            <Toast variant="error" message={errorMessage} onClose={() => setErrorMessage("")} /> 
          )}
        </form>
      </StyledFormComponent>
    </StyledLoginBackground>
  );
}

export default Login;
