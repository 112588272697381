import { Box, Stack, Typography } from '@mui/material';
import SuccessComponent from "../components/SuccessComponent"
import { StyledSuccessBackgroundComponent, StyledSuccessComponent, StyledSuccessComponentDate } from "../styles/styledComponent"
import Lottie from "lottie-react"
import { successLogo } from "../assets"
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const Success = () => {
  const location = useLocation();
  const studentData = location.state?.studentData;
  const navigate=useNavigate();

  useEffect(() => {
    if (!studentData) {
      navigate("/");
    }
  }, [studentData, navigate]);
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours24 = now.getHours();
  const minutes = String(now.getMinutes()).padStart(2, '0');

  const period = hours24 >= 12 ? 'PM' : 'AM';
  const hours12 = hours24 % 12 || 12; 

  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = `${hours12}:${minutes} ${period}`;

  return (
    <Box sx={{width:"90%",alignItems:"end",paddingTop:"2%",paddingLeft:"7%"}}>
        <StyledSuccessBackgroundComponent>
            <StyledSuccessComponentDate>
                <SuccessComponent label="Date:" value={formattedDate}/>            
                <SuccessComponent label="Time:" value={formattedTime}/> 
            </StyledSuccessComponentDate>
            <StyledSuccessComponent>
                <SuccessComponent label="Student Name: " value={studentData?.name}/>                 
                <SuccessComponent label="SETWIN Id:" value={studentData?.formId}/>                 
            </StyledSuccessComponent>    
        </StyledSuccessBackgroundComponent>
        <Stack alignItems={'center'} width={"100%"}>
          <Lottie animationData={successLogo} style={{ width: "350px",height: "350px"}} />
        </Stack>
        <Stack alignItems={'center'} width={"100%"}>
            <Typography sx={{color:"#121212",fontWeight:"600"}}>Thank you for your valuable feedback on SETWIN Online training</Typography>
        </Stack>
    </Box>
  )
}

export default Success
