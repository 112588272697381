import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import AutoComplete from '../components/FormFields/AutoComplete';
import FormInput from '../components/FormFields/FormInput';
import SelectComponent from '../components/FormFields/FormSelect';
import Heading from '../components/Heading';
import Toast from '../components/Toast/Toast';
import { StyledFeedBackComponent, StyledFeedbackStackComponent } from '../styles/styledComponent';
import { validationSchema } from '../validations/FeedbackFormValidations';
import PreviewForm from './preview';

const Form = () => {
  const location = useLocation();
  const studentData = location.state?.studentData;
  const navigate = useNavigate();



  useEffect(() => {
    if (!studentData) {
      navigate("/");
    }
  }, [studentData, navigate]);

  const defaultValues = {

    age: "",
    gender: '',
    priorEmployment: '',
    currentEmploymentStatus: '',
    socialGroup: '',
    // isConstructionWorker: '',
    source: '',
    isFirstTraining: '',
    qualityOfTraining: '',
    relevanceOfTraining: '',
    instructorEffectiveness: '',
    assessmentMethod: 'End-Of-Course Exam',
    isCertificationReceived: '',
    postTrainingConfidence: '',
    careerOpportunities: '',
    additionalTrainingRequired: '',
    recommendation: '',
    overallOpinion: '',
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  });

  const { handleSubmit, control,formState:{errors} } = methods;

  
  const years = Array.from({ length: 45 - 14 + 1 }, (_, i) => ({ label: `${14 + i} Years` }));




  const gender = ['Male', 'Female', 'Other'];
  const priorEmployment = ['Student', 'Unemployed', 'Employed Part-Time', 'Employed Full-Time', 'Self-Employed', 'Other'];
  const employmentStatus = ['Student', 'Unemployed', 'Employed Part-Time', 'Employed Full-Time', 'Self-Employed', 'Other'];
  const socialGroup = ['General (Forward Caste)', 'OBC', 'SC', 'ST', 'Minority'];
  const source = [
    'Phone Call From SETWIN',
    'Online Advertisement',
    'Social Media',
    'Friend Or Family Referral',
    'Job Center',
    'Local Community Board',
    'Newspaper Or Magazine',
    'Radio Or TV',
    'Other',
  ];
  const quality = ['Excellent', 'Good', 'Adequate', 'Poor', 'Not Applicable'];
  const trainingRelevance = ['Highly Relevant', 'Somewhat Relevant', 'Barely Relevant', 'Not Relevant'];
  const instructorEffectiveness = ['Extremely Effective', 'Moderately Effective', 'Slightly Effective', 'Ineffective', 'Not Applicable'];
  const assessmentMethod = ['End-Of-Course Exam', 'Continuous Assessment', 'Practical Assignments', 'No Formal Assessment', 'Other'];
  const postTrainingConfidence = ['Very Confident', 'Somewhat Confident', 'Neutral', 'Somewhat Unconfident', 'Not Confident At All'];
  const careerOpportunities = ['Many Opportunities', 'Some Opportunities', 'Few Opportunities', 'No Opportunities', 'Not Applicable'];
  const additionalTrainingNeeded = ['Possibly In The Future', 'No Additional Training Needed', 'Yes, Immediately', 'Yes, In The Near Future'];
  const recommendation = ['Highly Recommend', 'Recommend', 'Neutral', 'Would Not Recommend', 'Strongly Do Not Recommend'];
  const overallOpinion = ['Useful', 'Not Useful', 'Not Sure'];



  const [buttonValue, setButtonValue] = useState("next");
  const [formData, setFormData] = useState<any>({});
  const [error,setError]=useState(Object.keys(errors)?.length);

  const onSubmit = async (data: any) => {
    setFormData(data);
    setButtonValue("preview");
  };

  const handleReset = () => {
    setButtonValue("next");
  };
  if(error>0){
    return <Toast variant="error" message={"Fill all the fields"} onClose={() => setError(0)} /> ;
  }

  return (
    <FormProvider {...methods}>
      {buttonValue === 'next' && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledFeedBackComponent>
            <Typography color={'primary'} fontWeight={'600'} sx={{ marginTop: { xs: "15px", md: "40px", lg: "60px" }, fontSize: { xs: "18px", md: "28px" } }}>
              SETWIN Online Training Feedback
            </Typography>
            <Stack>
              <Heading value={'PARTICIPANT DEMOGRAPHICS'} />
              <StyledFeedbackStackComponent>
                <FormInput label={'Mobile Number'} value={studentData?.mobile} />
                <FormInput label={'Full Name Of The participant'} value={studentData?.name} />
              </StyledFeedbackStackComponent>
              <StyledFeedbackStackComponent>
                <FormInput label={'SETWIN Training ID'} value={studentData?.formId} />
                <FormInput label={'District'} value={studentData?.district} />
              </StyledFeedbackStackComponent>
              <StyledFeedbackStackComponent >
                <AutoComplete control={control} years={years} name={'age'} label={'Age Of The Participant'} placeholder="Enter Age Of The Participant" />
                <SelectComponent data={gender} control={control} name={'gender'} label={"Gender"} />
              </StyledFeedbackStackComponent>
              <StyledFeedbackStackComponent>
                <SelectComponent data={priorEmployment} control={control} name={'priorEmployment'} label={"Employment Status Prior To Training"} />
                <SelectComponent data={employmentStatus} control={control} name={'currentEmploymentStatus'} label={"Current Employment Status"} />
              </StyledFeedbackStackComponent>
              <StyledFeedbackStackComponent>
                <SelectComponent data={socialGroup} control={control} name={'socialGroup'} label={"Social Group"} />
                <FormInput label={'Is Your Parents/Father A Construction Worker?'} value={`Yes`} />
                {/* <SelectComponent data={["Yes", "No"]} control={control} name={'isConstructionWorker'} label={"Is Your Parents/Father A Construction Worker?"} /> */}
              </StyledFeedbackStackComponent>
            </Stack>
            <Stack>
              <Heading value={'PRE-TRAINING INFORMATION'}/>
              <StyledFeedbackStackComponent>
                <SelectComponent data={source} control={control} name={'source'} label={"How Did You Hear About The Training Program?"} />
                <SelectComponent data={["Yes","No"]} control={control} name={'isFirstTraining'} label={"Was This Your First Skill Training Program?"} />
              </StyledFeedbackStackComponent>
            </Stack>
            <Stack>
              <Heading value={'TRAINING DETAILS'}/>
              <StyledFeedbackStackComponent>
                <FormInput label={'Name Of The Training Course Attended'} value={studentData?.course?.name} />
                <FormInput label={'Duration Of The Course'} value={`${studentData?.course?.duration} Months`} />
              </StyledFeedbackStackComponent>
              <StyledFeedbackStackComponent>
                <SelectComponent data={quality} control={control} name={'qualityOfTraining'} label={"Quality Of Training Materials Provided"} />
                <SelectComponent data={trainingRelevance} control={control} name={'relevanceOfTraining'} label={"Relevance Of The Training Content To Your Expected Job Role"} />
              </StyledFeedbackStackComponent>
              <StyledFeedbackStackComponent>
                <SelectComponent data={instructorEffectiveness} control={control} name={'instructorEffectiveness'} label={"Instructors Teaching Method Effectiveness"} />
                {/* <SelectComponent data={assessmentMethod} control={control} name={'assessmentMethod'} label={"Assessment Methods Used During The Training"} /> */}
                <FormInput label={'Assessment Methods Used During The Training'} value={"End-Of-Course Exam"} />
              </StyledFeedbackStackComponent>
            </Stack>
            <Stack>
              <Heading value={'TRAINING EVALUATION'}/>
              <StyledFeedbackStackComponent>
                <SelectComponent data={["Yes","No"]} control={control} name={'isCertificationReceived'} label={"Certification Received"} />
                <SelectComponent data={postTrainingConfidence} control={control} name={'postTrainingConfidence'} label={"How Did You Feel Immediately After Completing Training"} />
              </StyledFeedbackStackComponent>
              <StyledFeedbackStackComponent>
                <SelectComponent data={careerOpportunities} control={control} name={'careerOpportunities'} label={"Opportunities For Career Advancement Post- Training"} />
                <SelectComponent data={additionalTrainingNeeded} control={control} name={'additionalTrainingRequired'} label={"Additional Training Or Skills Needed"} />
              </StyledFeedbackStackComponent>
              <StyledFeedbackStackComponent>
                <SelectComponent data={recommendation} control={control} name={'recommendation'} label={"Recommendations For Others Considering Similar Training"} />
                <SelectComponent data={overallOpinion} control={control} name={'overallOpinion'} label={"Overall Opinion About The Programme"} />
              </StyledFeedbackStackComponent>
            </Stack>
            <Button type='submit' variant='contained' sx={{ width: { xs: "25%", sm: "15%", md: "15%" }, fontWeight: "500" }}>Next</Button>
          </StyledFeedBackComponent>
        </form>
      )}
      {buttonValue === 'preview' && (
        <PreviewForm
          setButtonValue={setButtonValue}
          formData={formData}
          
          studentData={studentData}
        />
      )}
      
      {Object.keys(errors)?.length>0  && (
        <Toast variant="error" message={"Fill all the fields"}  onClose={() => setError(0)}/> 
      )}
    </FormProvider>
  );
}

export default Form;