import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    age:yup.string().required("Age is required"),
    gender: yup.string().required('Gender is required'),
    priorEmployment: yup.string().required('Employment Status is required'),
    currentEmploymentStatus: yup.string().required('Current Employment Status is required'),
    socialGroup: yup.string().required('Social Group is required'),
    // isConstructionWorker: yup.string().required('This field is required'),
    source: yup.string().required('This field is required'),
    isFirstTraining: yup.string().required('This field is required'),
    qualityOfTraining: yup.string().required('This field is required'),
    relevanceOfTraining: yup.string().required('This field is required'),
    instructorEffectiveness: yup.string().required('This field is required'),
    // assessmentMethod: yup.string().required('This field is required'),
    isCertificationReceived: yup.string().required('This field is required'),
    postTrainingConfidence: yup.string().required('This field is required'),
    careerOpportunities: yup.string().required('This field is required'),
    additionalTrainingRequired: yup.string().required('This field is required'),
    recommendation: yup.string().required('This field is required'),
    overallOpinion: yup.string().required('This field is required'),
  });

