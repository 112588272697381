import { FormControl, FormHelperText, FormLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

const FormSelect = ({ data, control,name,label }: any) => {
  return (
    <FormControl sx={{ gap:"0px", width: { xs: '80%', sm: '60%', md: '40%' } }}>
      <Stack direction={'row'} gap={0.5} alignItems={'center'}>
        <FormLabel sx={{ my: '5px',fontSize:"16px",marginTop: '15px',fontWeight:"500",opacity:"60%" }}>{label}</FormLabel>
        <Typography color="red">*</Typography>
      </Stack>

      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <Select
              {...field}
              error={!!fieldState?.error}
              displayEmpty
              sx={{ borderRadius: '10px',width:'auto' }}
              renderValue={(selected) =>
                selected ? (
                  selected 
                ) : (
                  <Typography variant='h6' sx={{ fontSize: '14px',fontFamily:"Poppins", fontWeight: '500', color: '#222222',opacity:"50%" }}>Select </Typography>
                )
              }
            >
              {data?.map((item: string, index: number) => (
                <MenuItem key={index} value={item}>
                  <Typography variant='h6' sx={{fontWeight:"500",color:"#222222"}}>{item}</Typography>
                </MenuItem>
              ))}
            </Select>
            {fieldState?.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
          </>
        )}
      />
    </FormControl>
  );
};

export default FormSelect;
