import { Stack, Typography } from '@mui/material'
import React from 'react'

const SuccessComponent = (props:any) => {
  return (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
        <Typography sx={{color:"#020202",fontWeight:"500",fontSize:"18px"}}>{props.label}</Typography>
        <Typography sx={{color:"#1E4B8F",fontWeight:"600",fontSize:"18px"}}>{props.value}</Typography>
    </Stack>
  )
}

export default SuccessComponent
