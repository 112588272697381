import { Box, Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { submitFeedback } from '../api/service';
import { Icons } from '../assets';
import Heading from '../components/Heading';
import PreviewComponent from '../components/PreviewComponent';
import Toast from '../components/Toast/Toast';
import { StyledFeedBackComponent, StyledFeedbackStackComponent } from '../styles/styledComponent';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';

const Preview = ({ setButtonValue, formData, studentData }: any) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting,setIsSubmitting]=useState(true)
  const navigate=useNavigate();

  const { mutate,isLoading } = useMutation(submitFeedback, {
    onSuccess: () => {
      navigate("/success",{state:{studentData}} )
      setButtonValue("success")
      setIsSubmitting(false)
    },
    onError: (error: any) => {
      setIsSubmitting(true)
      const message = error?.response?.data?.message || "An error occurred";
      setErrorMessage(message);
      setTimeout(() => setErrorMessage(""), 3000);
    },
  });

  const handleBackClick = () => setButtonValue("next");
  const handleFormSubmit = () => {
    setIsSubmitting(false)
    const preparedData = {
      ...formData,
      isConstructionWorker:true,
      assessmentMethod:'End-Of-Course Exam',
      isCertificationReceived: formData.isCertificationReceived === 'Yes',
      isFirstTraining: formData.isFirstTraining === 'Yes',
      formId: studentData?.formId,
    };
    mutate(preparedData);
  };

  const sections = [
    {
      title: 'PARTICIPANT DEMOGRAPHICS',
      fields: [
        { label: 'Mobile Number', value: studentData?.mobile },
        { label: 'Full Name Of The participant', value: studentData?.name },
        { label: 'SETWIN Training ID', value: studentData?.formId },
        { label: 'District', value: studentData?.district },
        { label: 'Age Of The Participant', value: formData.age },
        { label: 'Gender', value: formData.gender },
        { label: 'Employment Status Prior To Training', value: formData.priorEmployment },
        { label: 'Current Employment Status', value: formData.currentEmploymentStatus },
        { label: 'Social Group', value: formData.socialGroup },
        { label: 'Is Your Parents/father A Construction worker?', value: "Yes" },
      ],
    },
    {
      title: 'PRE-TRAINING INFORMATION',
      fields: [
        { label: 'How Did You Hear About The Training Program?', value: formData.source },
        { label: 'Was This your First Skill Training Program?', value: formData.isFirstTraining },
      ],
    },
    {
      title: 'TRAINING DETAILS',
      fields: [
        { label: 'Name Of The Training Course Attended', value: studentData?.course?.name },
        { label: 'Duration Of The Course', value: `${studentData?.course?.duration} Months` },
        { label: 'Quality Of Training Materials Provided', value: formData.qualityOfTraining },
        { label: 'Relevance Of The Training Content To Your Expected Job Role', value: formData.relevanceOfTraining },
        { label: 'Instructors Teaching Method Effectiveness', value: formData.instructorEffectiveness },
        { label: 'Assessment Methods Used During The Training', value: formData.assessmentMethod },
      ],
    },
    {
      title: 'TRAINING EVALUATION',
      fields: [
        { label: 'Certification Received', value: formData.isCertificationReceived },
        { label: 'How Did You Feel Immediately After Completing Training', value: formData.postTrainingConfidence },
        { label: 'Opportunities For Career Advancement Post- Training', value: formData.careerOpportunities },
        { label: 'Additional Training Or Skills Needed', value: formData.additionalTrainingRequired },
        { label: 'Recommendations For Others Considering Similar Training', value: formData.recommendation },
        { label: 'Overall Opinion About The Programme', value: formData.overallOpinion },
      ],
    },
  ];

  return (
    <>
      <Box sx={{ paddingTop: "2%" }}>
        <Button
          variant='outlined'
          sx={{ width: "15%" }}
          startIcon={<Icons.ArrowBackIcon />}
          onClick={handleBackClick}
        >
          Back
        </Button>
      </Box>
      <StyledFeedBackComponent>
        <Typography
          color='primary'
          fontWeight='600'
          sx={{ marginTop: { xs: "15px", md: "30px", lg: "40px" }, fontSize: { xs: "18px", md: "28px" } }}
        >
          SETWIN Online Training Feedback
        </Typography>
        <Box>
          <Typography
            variant='h4'
            color='black'
            sx={{ marginTop: { xs: "5px", md: "10px", lg: "10px" }, fontSize: { xs: "18px", md: "20px" } }}
          >
            Please Review the form and Submit
          </Typography>
        </Box>
        {sections.map((section) => (
          <Stack key={section.title}>
            <Heading value={section.title} />
            {section.fields.map((field, index) => (
              index % 2 === 0 && (
                <StyledFeedbackStackComponent key={field.label}>
                  <PreviewComponent label={section.fields[index].label} value={section.fields[index].value} />
                  {section.fields[index + 1] && (
                    <PreviewComponent label={section.fields[index + 1].label} value={section.fields[index + 1].value} />
                  )}
                </StyledFeedbackStackComponent>
              )
            ))}
          </Stack>
        ))}
        {isSubmitting ? <Button variant='contained' onClick={handleFormSubmit} sx={{ width: { xs: "25%", sm: "15%", md: "15%" } }}>
          Submit
        </Button>
        :
          <Button variant='contained' onClick={handleFormSubmit} sx={{ width: { xs: "25%", sm: "15%", md: "15%" } }}>
          {isLoading && <Loader/>}
          </Button>
        }
        {errorMessage && (
          <Toast variant="error" message={errorMessage} onClose={() => setErrorMessage("")} />
        )}
      </StyledFeedBackComponent>
    </>
  );
};

export default Preview;
