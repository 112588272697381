import { setwinLogo, telanganaLogo } from '../assets';
import Image from '../components/Image';
import { StyledNavbarComponent } from '../styles/styledComponent';

const NavBar = () => {  
  return (
    <StyledNavbarComponent>
      <Image src={telanganaLogo} />
      <Image src={setwinLogo}/>
    </StyledNavbarComponent>
  )
}

export default NavBar
